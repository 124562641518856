<template>
    <div class="appContentThir">
        <div class="acThirBox">
            <!-- 标题 -->
            <!-- <div class="acThirTop wow animate__animated animate__fadeInUp">为什么要选威有做小程序</div> -->
            <!-- 背景图 -->
            <div class="acThirImg">
                <img class="acTImg wow animate__animated animate__fadeInUp" src="http://iv.okvu.cn/vugw/img/H_img_1.png" alt="" />
            </div>
            <!-- 文字 -->
            <div class="acThirText">
                <ul>
                    <li class="attLi wow animate__animated animate__fadeIn" data-wow-delay="1.3s">
                        <div>
                            <img src="https://iv.okvu.cn/vugw/img/vu1212.png" alt="" />
                        </div>
                        <p class="attLBig">高效定制，按时完成</p>
                        <p class="attLSmall">丰富开发经验和专业的技术团队，确保软件定制开发如期完成</p>
                    </li>
                    <li class="attLi wow animate__animated animate__fadeIn" data-wow-delay="1s">
                        <div>
                            <img src="https://iv.okvu.cn/vugw/img/vu1212.png" alt="" />
                        </div>
                        <p class="attLBig">{{ getWebData().year }}年+经验，实力雄厚</p>
                        <p class="attLSmall">软件开发经验丰富，专业可靠</p>
                    </li>
                    <li class="attLi wow animate__animated animate__fadeIn" data-wow-delay="1.6s">
                        <div>
                            <img src="https://iv.okvu.cn/vugw/img/vu1212.png" alt="" />
                        </div>
                        <p class="attLBig">量身定制，按需开发</p>
                        <p class="attLSmall">根据客户的需求，为客户量身定制软件开发方案</p>
                    </li>
                    <li class="attLi wow animate__animated animate__fadeIn" data-wow-delay="1.9s">
                        <div>
                            <img src="https://iv.okvu.cn/vugw/img/vu1212.png" alt="" />
                        </div>
                        <p class="attLBig">稳定可靠，安全高效</p>
                        <p class="attLSmall">{{ getWebData().year }}年+技术优势，200+专业团队，服务有保障，软件无后顾之忧</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AlcOne",
    inject: ["getWebData"],
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        setTimeout(() => {
            new this.$wow.WOW().init({
                boxClass: "wow",
                animateClass: "animate__animated",
                offset: 0,
                mobile: true,
                live: true,
            });
        }, 1);
    },
};
</script>

<style lang="less" scoped>
.appContentThir {
    width: 100%;
    height: 775px;
    background: url("http://iv.okvu.cn/vugw/img/H_img_2.jpg") center top no-repeat;
    .acThirBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        // 标题
        .acThirTop {
            width: 50%;
            height: 50px;
            margin: 0 auto;
            margin-top: 80px;
            line-height: 50px;
            text-align: center;
            font-size: 36px;
        }
        // 背景图
        .acThirImg {
            width: 100%;
            height: 700px;
            margin-top: 150px;
            text-align: center;
            .acTImg {
                width: 90%;
                height: 90%;
            }
        }
        // 文字
        .acThirText {
            .attLi {
                text-align: left;
                width: 300px;
                height: 100px;
                & > div {
                    width: 70px;
                    height: 70px;
                    overflow: hidden;
                    position: absolute;
                    left: -90px;
                    top: 10px;
                    & > img {
                        position: absolute;
                        width: 900px;
                    }
                }
                .attLBig {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 20px;
                    font-weight: 900;
                }
                .attLSmall {
                    width: 100%;
                    height: 50px;
                    line-height: 25px;
                    font-size: 16px;
                }
            }
            .attLi:nth-child(1) {
                position: absolute;
                top: 140px;
                left: 100px;
                img {
                    top: -1409px;
                    left: -76px;
                }
            }
            .attLi:nth-child(2) {
                position: absolute;
                top: 310px;
                left: 80px;
                img {
                    top: -1409px;
                    left: 0px;
                }
            }
            .attLi:nth-child(3) {
                position: absolute;
                top: 60px;
                right: 320px;
                img {
                    top: -1409px;
                    left: -160px;
                }
            }
            .attLi:nth-child(4) {
                position: absolute;
                top: 200px;
                right: 0px;
                img {
                    top: -1409px;
                    left: -240px;
                }
            }
        }
    }
}
</style>
